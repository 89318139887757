import React from "react";
import PageWrapper from "../components/PageWrapper";
import NotFound from "../sections/landing/NotFound";

const Page404 = () => {
  return (
    <NotFound />
  );
};
export default Page404;
