import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import imgHero from "../../assets/image/l1/png/l1-hero-img.png";

const NotFound = () => {
  return (
    <>
      <div className="pb-11 pb-lg-14 pt-25 pt-lg-29 bg-default-1 position-relative">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col
              sm="10"
              md="9"
              lg="7"
              xl="6"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              style={{
                border: "1px solid #E8EEFE",
                padding: "40px",
                margin: "20px",
                borderRadius: "20px",
              }}
            >
              <div className="hero-btns d-flex flex-column justify-content-center align-items-center mb-11">
                <Illustration />
              </div>

              <div className="hero-content text-center mt=8">
                <h1
                  className="gr-text-3 mb-8"
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    lineHeight: "32px",
                    textAlign: "center",
                    color: "#4F7EFE",
                  }}
                >
                  Oops! Something is missing
                </h1>
                <p
                  className="gr-text-8"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#4A4F56",
                  }}
                >
                  We can't seem to find the page you're looking for.{" "}
                </p>
                <div className="hero-btns d-flex flex-column flex-md-row justify-content-md-center mt-11">
                  <a
                    href="https://www.referralkit.co"
                    className="btn btn-primary with-icon gr-hover-y mb-6 mb-md-0 mr-md-7"
                  >
                    Back To Homepage
                    <i className="fas fa-arrow-right gr-text-11"></i>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NotFound;

const Illustration = () => {
  return (
    <svg
      width="206"
      height="157"
      viewBox="0 0 206 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M7.94958 156.552L0.405459 63.4784C0.215541 61.1075 0.877454 58.7465 2.27239 56.8202L40.178 4.40851C41.1912 3.00763 42.5509 1.89392 44.1238 1.17634C45.6968 0.458756 47.4292 0.161915 49.1512 0.314834L178.837 11.8169C180.889 11.9985 182.836 12.8114 184.409 14.1436C185.981 15.4759 187.103 17.2622 187.619 19.2574L205.402 87.8487C205.84 89.5347 205.828 91.3061 205.368 92.9861L187.899 156.879L7.94958 156.552Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M94.6113 32.835L73.4521 87.8434L136.023 88.3339L115.317 32.835H94.6113Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M195.007 156.833H1.9218C1.88497 156.84 1.84712 156.837 1.81114 156.827C1.77499 156.818 1.74156 156.8 1.71304 156.776C1.68453 156.752 1.66162 156.721 1.646 156.688C1.63022 156.654 1.62207 156.616 1.62207 156.579C1.62207 156.542 1.63022 156.504 1.646 156.47C1.66162 156.436 1.68453 156.406 1.71304 156.382C1.74156 156.358 1.77499 156.34 1.81114 156.331C1.84712 156.321 1.88497 156.318 1.9218 156.324H195.007C195.044 156.318 195.082 156.321 195.117 156.331C195.155 156.34 195.187 156.358 195.216 156.382C195.245 156.406 195.267 156.436 195.284 156.47C195.299 156.504 195.307 156.542 195.307 156.579C195.307 156.616 195.299 156.654 195.284 156.688C195.267 156.721 195.245 156.752 195.216 156.776C195.187 156.8 195.155 156.818 195.117 156.827C195.082 156.837 195.044 156.84 195.007 156.833Z"
          fill="#B2B6BB"
        />
        <path
          d="M63.3427 31.1906H25.1045V82.7452H63.3427V31.1906Z"
          fill="#B2B6BB"
        />
        <path
          opacity="0.1"
          d="M63.3427 31.1906H25.1045V82.7452H63.3427V31.1906Z"
          fill="#4A4F56"
        />
        <path
          d="M62.4267 31.1906H24.1885V82.7452H62.4267V31.1906Z"
          fill="#B2B6BB"
        />
        <path
          d="M60.3189 33.6564H26.1709V82.5922H60.3189V33.6564Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.7"
          d="M26.1709 50.5709L37.1688 33.6581H47.0025L26.1709 64.2708V50.5709Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M26.1709 82.5192V71.4941L52.9716 33.6581L60.3341 33.6598V40.4215L29.7758 82.6075L26.1709 82.5192Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M38.5215 82.4889L60.3341 52.3328V59.2439L43.3738 82.5941L38.5215 82.4889Z"
          fill="white"
        />
        <path
          d="M64.5703 82.4497H21.1216V84.7087H64.5703V82.4497Z"
          fill="#B2B6BB"
        />
        <path
          d="M43.3716 32.538H42.3193V83.58H43.3716V32.538Z"
          fill="#B2B6BB"
        />
        <path
          d="M61.9762 44.7646H24.3286V45.8168H61.9762V44.7646Z"
          fill="#B2B6BB"
        />
        <path
          d="M61.9767 56.6064H24.3291V57.6586H61.9767V56.6064Z"
          fill="#B2B6BB"
        />
        <path
          d="M61.9762 68.4477H24.3286V69.4999H61.9762V68.4477Z"
          fill="#B2B6BB"
        />
        <path
          d="M62.3967 81.9318H24.1313V82.4733H62.3967V81.9318Z"
          fill="#B2B6BB"
        />
        <path
          opacity="0.1"
          d="M62.3967 81.9318H24.1313V82.4733H62.3967V81.9318Z"
          fill="#4A4F56"
        />
        <path
          d="M104.71 35.7203C106.444 35.7203 107.85 34.3145 107.85 32.5804C107.85 30.8464 106.444 29.4406 104.71 29.4406C102.976 29.4406 101.57 30.8464 101.57 32.5804C101.57 34.3145 102.976 35.7203 104.71 35.7203Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M108.281 26.6437C106.869 26.0752 106.692 24.0334 106.692 24.0334H103.128C103.128 24.0334 102.958 26.0701 101.539 26.6437C100.85 26.9204 99.3057 27.2513 97.8393 27.8572C96.8695 28.2579 96.0403 28.9373 95.457 29.8097C94.8736 30.6819 94.562 31.7077 94.562 32.7571V32.7758H115.258V32.7571C115.258 31.7077 114.946 30.6819 114.363 29.8097C113.779 28.9373 112.95 28.2579 111.981 27.8572C110.514 27.2513 108.97 26.9221 108.281 26.6437Z"
          fill="#868A91"
        />
        <path
          d="M106.312 23.5477H103.508V24.0331H106.312V23.5477Z"
          fill="#61656D"
        />
        <path
          d="M105.868 22.5685H103.952V23.5478H105.868V22.5685Z"
          fill="#61656D"
        />
        <path
          d="M106.312 23.5477H103.508V24.0331H106.312V23.5477Z"
          fill="#61656D"
        />
        <path
          d="M105.868 22.5685H103.952V23.5478H105.868V22.5685Z"
          fill="#61656D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M105.08 22.5684H104.74V5.34511L105.08 5.37057V22.5684Z"
          fill="#61656D"
        />
        <path
          opacity="0.2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M115.257 32.7571C115.257 31.7077 114.946 30.6819 114.362 29.8097C113.779 28.9373 112.95 28.2579 111.98 27.8572C110.514 27.2513 108.969 26.9221 108.28 26.6437C106.868 26.0752 106.692 24.0334 106.692 24.0334H105.931L105.867 24.0453C105.867 24.0453 105.643 26.5555 107.849 27.6026C108.813 28.0592 110.699 28.9027 111.371 29.731C111.985 30.4896 112.06 31.4876 112.06 32.7758H115.266L115.257 32.7571Z"
          fill="#4A4F56"
        />
        <path
          d="M47.15 156.392H139.404L152.012 88.1254C152.068 87.8238 152.057 87.5137 151.98 87.217C151.902 86.9203 151.76 86.6444 151.564 86.4086C151.367 86.1729 151.121 85.9833 150.845 85.8531C150.566 85.7231 150.264 85.6558 149.957 85.6559H57.7033L45.0947 153.921C45.0387 154.223 45.0497 154.534 45.1271 154.831C45.2045 155.128 45.3464 155.403 45.5426 155.639C45.7388 155.875 45.9846 156.065 46.2624 156.195C46.5402 156.324 46.8434 156.392 47.15 156.392Z"
          fill="url(#paint1_linear)"
        />
        <path
          opacity="0.2"
          d="M47.15 156.392H139.404L152.012 88.1254C152.068 87.8238 152.057 87.5137 151.98 87.217C151.902 86.9203 151.76 86.6444 151.564 86.4086C151.367 86.1729 151.121 85.9833 150.845 85.8531C150.566 85.7231 150.264 85.6558 149.957 85.6559H57.7033L45.0947 153.921C45.0387 154.223 45.0497 154.534 45.1271 154.831C45.2045 155.128 45.3464 155.403 45.5426 155.639C45.7388 155.875 45.9846 156.065 46.2624 156.195C46.5402 156.324 46.8434 156.392 47.15 156.392Z"
          fill="#4A4F56"
        />
        <path
          d="M139.405 156.392H46.4958C45.9841 156.392 45.4902 156.206 45.1075 155.864C44.7249 155.525 44.4804 155.057 44.4201 154.549L36.7521 90.1379C36.717 89.8446 36.7446 89.5473 36.8329 89.2654C36.9213 88.9836 37.0683 88.7236 37.2645 88.5028C37.4607 88.282 37.7013 88.1052 37.9709 87.9842C38.2404 87.8631 38.5323 87.8007 38.8278 87.8009H129.383C129.894 87.8007 130.388 87.9882 130.771 88.328C131.153 88.6676 131.398 89.1361 131.458 89.644L139.405 156.392Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M57.6304 86.0495L59.0815 79.2233C59.184 78.7392 59.4496 78.3051 59.834 77.9937C60.2185 77.6822 60.6981 77.5123 61.1928 77.5125H75.2797C75.7583 77.5123 76.2233 77.671 76.6018 77.9638C76.9803 78.2565 77.2507 78.6668 77.3707 79.1299L79.1493 86.0037L57.6304 86.0495Z"
          fill="url(#paint3_linear)"
        />
        <path
          opacity="0.2"
          d="M57.6304 86.0495L59.0815 79.2233C59.184 78.7392 59.4496 78.3051 59.834 77.9937C60.2185 77.6822 60.6981 77.5123 61.1928 77.5125H75.2797C75.7583 77.5123 76.2233 77.671 76.6018 77.9638C76.9803 78.2565 77.2507 78.6668 77.3707 79.1299L79.1493 86.0037L57.6304 86.0495Z"
          fill="#4A4F56"
        />
        <g opacity="0.4">
          <path
            d="M69.5661 128H76.2152V130.435H79.0156V128H80.7336V125.652H79.0156V116.582H75.3494L69.5661 125.693V128ZM76.2693 125.652H72.5153V125.544L76.1611 119.774H76.2693V125.652ZM88.1167 130.739C91.6002 130.739 93.7038 128.088 93.7106 123.522C93.7173 118.99 91.5866 116.392 88.1167 116.392C84.6399 116.392 82.5295 118.983 82.5227 123.522C82.5092 128.074 84.6264 130.732 88.1167 130.739ZM88.1167 128.311C86.5271 128.311 85.4989 126.714 85.5057 123.522C85.5125 120.376 86.5339 118.794 88.1167 118.794C89.6927 118.794 90.7208 120.376 90.7208 123.522C90.7276 126.714 89.6995 128.311 88.1167 128.311ZM95.5893 128H102.238V130.435H105.039V128H106.757V125.652H105.039V116.582H101.373L95.5893 125.693V128ZM102.293 125.652H98.5384V125.544L102.184 119.774H102.293V125.652Z"
            fill="#BCCEFE"
          />
        </g>
        <path
          d="M163.496 123.475L156.453 123.274L155.735 146.772C155.713 147.426 155.538 148.066 155.225 148.639L152.323 154.055L155.516 154.145L159.861 147.879C159.861 147.879 163.472 129.793 163.496 123.475Z"
          fill="#FC9D9D"
        />
        <path
          opacity="0.15"
          d="M163.496 123.544L161.396 123.485L161.491 125.012C161.4 128.249 161.474 131.487 160.889 134.673L159.119 144.951L158.737 146.688C158.569 147.455 158.24 148.176 157.771 148.807L155.565 151.798C155.176 152.329 154.657 152.753 154.058 153.03C153.52 153.276 152.948 153.437 152.36 153.51L154.414 154.541L159.882 147.889C159.882 147.889 163.447 129.924 163.496 123.544Z"
          fill="#4A4F56"
        />
        <path
          d="M172.5 146.942C172.354 145.549 170.54 128.443 168.766 122.64L162.023 123.256L161.996 124.008C161.982 124.351 162.023 124.695 162.113 125.026L168.33 147.577C168.421 147.908 168.399 148.261 168.27 148.578L166.038 154.031L168.538 153.614L173.121 148.2L172.5 146.942Z"
          fill="#FC9D9D"
        />
        <path
          d="M172.639 106.664C174.455 93.8176 170.561 85.5895 170.561 85.5895H163.413H163.302H163.194L155.896 85.4792C155.896 85.4792 152.658 93.2439 153.414 106.158C153.788 112.54 154.156 117.287 154.733 122.877L154.771 123.23L162.184 123.427H162.403L171.761 123.675L171.816 123.325C171.665 120.683 171.682 113.434 172.639 106.664Z"
          fill="#4A4F56"
        />
        <path
          d="M128.141 85.1498C128.02 85.0911 127.92 84.9962 127.856 84.8782C127.838 84.8472 127.829 84.8121 127.829 84.7764C127.828 84.7406 127.838 84.7055 127.856 84.6746C127.872 84.6445 127.897 84.6198 127.927 84.6033C127.957 84.5868 127.991 84.5797 128.026 84.5829L129.468 84.6746L128.141 85.1498Z"
          fill="#F39494"
        />
        <path
          d="M129.604 84.6849L128.925 84.6408C128.662 84.4812 128.893 84.2216 128.968 84.2097C129.042 84.1978 130.431 84.3913 130.431 84.3913L129.604 84.6849Z"
          fill="#F39494"
        />
        <path
          opacity="0.15"
          d="M129.564 84.6969L128.089 85.1501L127.909 84.9685C127.909 84.9685 129.068 84.6851 129.131 84.6511L129.564 84.6969Z"
          fill="#4A4F56"
        />
        <path
          d="M153.712 59.3114C153.673 59.6661 153.732 60.0245 153.881 60.3484C154.068 60.7844 154.419 61.1291 154.859 61.3071C155.297 61.4852 155.789 61.4821 156.227 61.2988C156.429 61.2204 156.651 61.2043 156.862 61.2526C157.074 61.301 157.267 61.4117 157.415 61.5704C157.702 61.8726 158.067 62.0904 158.469 62.2005C158.871 62.3107 159.296 62.3087 159.696 62.1949C160.017 62.0962 160.314 61.9317 160.567 61.7121C160.821 61.4923 161.027 61.2223 161.169 60.919C161.314 60.6157 161.392 60.2857 161.402 59.9502C161.41 59.6148 161.351 59.281 161.224 58.9702C161.039 58.487 160.697 58.079 160.254 57.81C159.813 57.541 159.294 57.4261 158.78 57.4835C158.435 57.5271 158.104 57.6483 157.812 57.8378C157.52 58.0274 157.276 58.2805 157.096 58.5782C156.933 58.2968 156.699 58.0629 156.417 57.8993C156.673 57.5948 156.852 57.2325 156.936 56.8436C157.021 56.4548 157.009 56.051 156.904 55.6674C156.826 55.3591 156.685 55.0699 156.492 54.8177C156.297 54.5654 156.054 54.3555 155.776 54.2009C155.497 54.0463 155.19 53.95 154.874 53.9183C154.557 53.8866 154.238 53.9198 153.934 54.0161C153.523 54.1644 153.16 54.4203 152.882 54.7572C152.603 55.0941 152.42 55.4994 152.352 55.931C152.284 56.3626 152.332 56.8047 152.493 57.2109C152.654 57.6172 152.921 57.9728 153.267 58.2404C153.428 58.365 153.554 58.5293 153.632 58.717C153.708 58.9047 153.737 59.1096 153.712 59.3114Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.15"
          d="M130.405 84.3915L129.299 84.6749L129.129 84.6511L130.193 84.3694L130.405 84.3915Z"
          fill="#4A4F56"
        />
        <path
          d="M159.767 61.6317L159.555 55.9901L163.859 55.6931L164.307 57.3038L164.866 61.7573C164.891 61.9563 164.976 62.143 165.11 62.2921C165.246 62.4411 165.422 62.5453 165.618 62.5906L167.296 62.981C167.296 62.981 166.634 67.3564 162.586 67.426C162.406 67.426 162.227 67.4345 162.048 67.4412C161.117 67.4684 157.873 67.2511 157.162 62.981L158.973 62.6313C159.204 62.5872 159.409 62.4621 159.555 62.2786C159.701 62.0953 159.776 61.8657 159.767 61.6317Z"
          fill="#FC9D9D"
        />
        <path
          opacity="0.15"
          d="M159.632 60.5573C159.851 60.6105 160.077 60.6288 160.301 60.6116C160.995 60.5565 161.652 60.2737 162.167 59.8072C162.753 59.7021 163.308 59.4704 163.795 59.1283C163.817 59.1106 163.832 59.0848 163.838 59.0563C163.841 59.028 163.834 58.9991 163.817 58.9755C163.809 58.9638 163.798 58.9538 163.787 58.9462C163.775 58.9387 163.761 58.9336 163.748 58.9314C163.734 58.929 163.72 58.9297 163.705 58.9333C163.692 58.9368 163.68 58.9431 163.668 58.9518C163.293 59.2142 162.872 59.4041 162.427 59.5118C162.395 59.5118 162.364 59.5339 162.332 59.5356C162.235 59.5577 162.162 59.5628 162.072 59.5865H162.04C161.986 59.6054 161.928 59.6152 161.87 59.6154L161.429 59.6442C160.823 59.669 160.221 59.5295 159.688 59.2403L159.632 60.5573Z"
          fill="#4A4F56"
        />
        <path
          d="M156.871 59.2334L159.974 57.3512L159.005 49.5695L156.265 50.116L155.037 51.3041C155.037 51.3041 155.14 51.7402 155.178 51.9965C155.317 52.9809 154.536 53.5529 153.638 54.1367L156.871 59.2334Z"
          fill="#4A4F56"
        />
        <path
          d="M149.941 69.9769L147.443 77.7518C147.443 77.7518 135.479 84.4558 135.41 84.3862C132.748 84.5339 131.82 83.984 131.676 84.006C131.532 84.0281 127.76 85.0329 126.728 85.3944C126.498 85.4741 126.465 85.8255 126.698 85.8356C126.93 85.8458 127.835 85.7338 128.477 85.6778C128.266 85.9833 127.835 86.7419 127.592 87.1153C127.571 87.1505 127.559 87.1898 127.555 87.2307C127.55 87.2727 127.555 87.3153 127.57 87.3546C127.585 87.3927 127.608 87.4269 127.638 87.4548C127.667 87.4814 127.702 87.5006 127.74 87.5108C128.079 87.6007 129.152 86.3109 129.172 86.2328C129.193 86.1547 130.489 85.8594 130.647 85.8136C130.805 85.7678 131.722 85.9833 131.847 86.3007C131.973 86.6181 131.026 87.2392 130.815 87.3411C130.605 87.4429 130.615 87.6924 130.637 87.8621C130.646 87.9057 130.67 87.9448 130.705 87.9724C130.739 87.9992 130.782 88.012 130.825 88.0081C131.368 87.883 131.885 87.6647 132.353 87.3631C132.754 87.0916 133.5 86.9117 133.828 86.8658C134.517 86.7616 135.213 86.7095 135.91 86.7097L149.476 82.6058C149.854 82.606 150.223 82.4898 150.531 82.2728C150.84 82.0561 151.075 81.7491 151.204 81.394L154.584 71.5688L149.941 69.9769Z"
          fill="#FC9D9D"
        />
        <path
          d="M157.316 56.0274L157.147 51.0852C157.135 50.8735 157.165 50.6615 157.236 50.4616C157.308 50.2617 157.416 50.0777 157.559 49.92C157.7 49.7625 157.871 49.6344 158.063 49.5431C158.255 49.4518 158.462 49.399 158.674 49.3879L163.499 49.2386C163.662 49.235 163.825 49.2639 163.978 49.3233C164.131 49.3829 164.271 49.472 164.389 49.5853C164.507 49.6989 164.602 49.8347 164.667 49.9849C164.733 50.1351 164.769 50.2968 164.772 50.4606L164.964 55.7797C165.04 57.8655 163.462 59.6154 161.425 59.6833H161.142C159.114 59.7647 157.408 58.1133 157.316 56.0274Z"
          fill="#FC9D9D"
        />
        <path
          d="M162.318 54.7289C162.31 54.651 162.279 54.5772 162.23 54.5166C162.181 54.4558 162.114 54.4112 162.04 54.388C161.965 54.3647 161.885 54.3642 161.809 54.3861C161.734 54.4082 161.666 54.4518 161.617 54.5115C161.566 54.5714 161.534 54.6448 161.526 54.7225C161.515 54.8004 161.531 54.8791 161.566 54.9489C161.6 55.0188 161.656 55.0769 161.724 55.1156C161.792 55.1543 161.87 55.1721 161.948 55.1668C162.055 55.1578 162.155 55.1066 162.223 55.0244C162.293 54.9424 162.327 54.8362 162.318 54.7289Z"
          fill="#4A4F56"
        />
        <path
          d="M161.843 54.8341C161.843 54.8233 161.839 54.8126 161.834 54.8027C161.829 54.7929 161.824 54.7841 161.816 54.7768C161.807 54.7695 161.797 54.764 161.787 54.7605C161.777 54.7569 161.766 54.7554 161.754 54.7561C161.738 54.7567 161.722 54.7625 161.709 54.7727C161.695 54.7827 161.683 54.7966 161.678 54.8126C161.673 54.8285 161.671 54.8457 161.676 54.8621C161.68 54.8784 161.69 54.8932 161.702 54.9044C161.715 54.9156 161.731 54.9227 161.748 54.9249C161.765 54.9271 161.782 54.9244 161.797 54.9168C161.812 54.9093 161.824 54.8973 161.833 54.8827C161.841 54.8679 161.844 54.8511 161.843 54.8341Z"
          fill="white"
        />
        <path
          d="M159.229 54.9716C159.22 54.8935 159.192 54.8195 159.141 54.7586C159.092 54.6976 159.025 54.6527 158.951 54.6292C158.876 54.6058 158.796 54.605 158.722 54.6267C158.645 54.6486 158.579 54.692 158.528 54.752C158.477 54.8117 158.445 54.885 158.436 54.9629C158.426 55.0408 158.44 55.1196 158.475 55.1897C158.511 55.2596 158.565 55.3176 158.635 55.3565C158.703 55.3952 158.781 55.4132 158.859 55.4078C158.966 55.3988 159.064 55.3477 159.134 55.266C159.204 55.1842 159.237 55.0785 159.229 54.9716Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.15"
          d="M128.395 85.6814L128.437 85.6016C128.503 85.4986 128.603 85.4227 128.72 85.3878L129.908 85.1043L128.761 85.4896C128.653 85.5249 128.558 85.5916 128.488 85.6814H128.395Z"
          fill="#4A4F56"
        />
        <path
          d="M158.751 55.0567C158.749 55.0409 158.742 55.0258 158.732 55.0134C158.722 55.0012 158.708 54.9922 158.693 54.9874C158.678 54.9828 158.661 54.9828 158.645 54.9874C158.63 54.9922 158.617 55.0012 158.606 55.0134C158.596 55.0258 158.591 55.0409 158.589 55.0567C158.588 55.0726 158.589 55.0888 158.598 55.103C158.605 55.1171 158.617 55.129 158.63 55.1366C158.644 55.1444 158.661 55.148 158.676 55.1466C158.686 55.1459 158.698 55.1429 158.707 55.1378C158.717 55.1329 158.725 55.1259 158.732 55.1176C158.739 55.1091 158.744 55.0994 158.747 55.0889C158.751 55.0786 158.752 55.0675 158.751 55.0567Z"
          fill="white"
        />
        <g opacity="0.15">
          <path
            opacity="0.15"
            d="M159.954 55.8118C159.954 55.6852 160.005 55.5638 160.095 55.4737C160.183 55.3836 160.304 55.332 160.431 55.3298C160.46 55.3374 160.487 55.3537 160.506 55.3768C160.526 55.3999 160.538 55.4285 160.541 55.4587C160.534 55.4883 160.519 55.5149 160.497 55.5351C160.475 55.5553 160.446 55.5677 160.417 55.5708C160.351 55.5769 160.29 55.6066 160.246 55.6544C160.2 55.7021 160.173 55.7648 160.171 55.8304C160.169 55.8627 160.175 55.8949 160.185 55.9253C160.197 55.9557 160.214 55.9835 160.234 56.0075C160.256 56.0312 160.283 56.0506 160.312 56.0641C160.341 56.0777 160.373 56.0854 160.405 56.0867L160.938 56.0986C160.971 56.1006 160.999 56.1152 161.022 56.1393C161.042 56.1633 161.054 56.1948 161.05 56.2267C161.049 56.2586 161.035 56.2885 161.01 56.3097C160.986 56.3309 160.955 56.3416 160.923 56.3396L160.392 56.3277C160.327 56.3294 160.263 56.3165 160.203 56.29C160.144 56.2634 160.091 56.224 160.049 56.1745C160.007 56.1251 159.976 56.0667 159.961 56.0041C159.944 55.9413 159.942 55.8756 159.954 55.8118Z"
            fill="#4A4F56"
          />
        </g>
        <path
          d="M155.78 51.1495C155.962 51.464 156.237 51.7155 156.564 51.8706C156.894 52.0259 157.262 52.0775 157.62 52.0184C158.224 51.9293 158.774 51.6232 159.168 51.1573C159.562 50.6916 159.772 50.0979 159.76 49.4879H159.782C160.045 49.7908 160.38 50.0239 160.755 50.1666C161.13 50.3094 161.535 50.3572 161.933 50.306C162.165 50.2754 162.391 50.2102 162.603 50.1125C163.07 52.7567 164.731 52.8501 164.731 52.8501C164.764 53.1047 164.828 53.6138 164.901 54.2197C164.955 54.6288 164.48 54.8613 164.731 55.143L164.604 55.16C164.68 55.2567 164.506 55.3959 164.563 55.4994C164.658 55.6826 164.719 55.8808 164.745 56.085C164.77 56.2537 164.77 56.4254 164.745 56.5941C164.674 57.0033 164.458 57.3732 164.136 57.6362C163.669 58.0266 163.163 58.5493 163.131 59.1807C163.09 59.9631 163.762 61.3497 165.694 61.1766C166.249 61.1274 170.706 60.6997 169.894 56.7639C169.791 56.441 169.638 56.1355 169.443 55.8575C169.321 55.6629 169.243 55.4439 169.214 55.216C169.141 54.6712 169.451 54.2571 169.779 53.8786C169.966 53.6853 170.106 53.4524 170.19 53.1973C170.273 52.9422 170.298 52.6715 170.263 52.4054C170.218 52.0412 170.062 51.6999 169.813 51.43C169.565 51.1602 169.237 50.9753 168.878 50.9017C168.777 50.8811 168.684 50.8319 168.609 50.7601C168.536 50.6882 168.486 50.5965 168.462 50.496C168.25 49.6356 167.085 47.4801 163.991 48.0487C163.991 47.1271 162.973 45.8423 161.615 45.9051C160.449 45.9543 159.562 46.9319 159.007 47.75C158.85 47.9867 158.638 48.1809 158.389 48.3153C158.139 48.4499 157.859 48.5203 157.576 48.5205C157.325 48.5205 156.951 48.4203 156.6 48.4645C155.242 48.6342 154.723 49.8562 155.045 51.3277L155.78 51.1495Z"
          fill="#4A4F56"
        />
        <path
          d="M164.485 56.1681L164.523 54.179C164.64 52.346 166.816 52.4512 166.631 54.2791C166.446 56.107 164.681 56.1325 164.681 56.1325L164.485 56.1681Z"
          fill="#FC9D9D"
        />
        <path
          opacity="0.15"
          d="M166.072 53.541C166.055 53.5675 166.031 53.5868 166.001 53.5957C165.972 53.6045 165.94 53.602 165.913 53.5885C165.858 53.5534 165.799 53.5291 165.736 53.5174C165.673 53.5059 165.609 53.5069 165.546 53.5207C165.488 53.5475 165.436 53.5853 165.392 53.6322C165.349 53.679 165.315 53.734 165.293 53.7939C165.215 53.9814 165.185 54.1843 165.2 54.3862V54.595C165.215 54.7647 164.974 54.7426 164.966 54.6052L164.949 54.4168C164.921 54.1712 164.962 53.9226 165.067 53.6989C165.1 53.6104 165.151 53.5298 165.217 53.4628C165.285 53.3957 165.364 53.3438 165.453 53.3102C165.544 53.2868 165.639 53.2825 165.731 53.2976C165.824 53.3129 165.913 53.3472 165.992 53.3985C166.02 53.4107 166.042 53.431 166.055 53.4567C166.07 53.4823 166.076 53.512 166.072 53.541Z"
          fill="#4A4F56"
        />
        <path
          d="M165.439 55.4234C165.432 55.355 165.405 55.2902 165.363 55.2369C165.318 55.1837 165.261 55.1445 165.195 55.1242C165.128 55.104 165.059 55.1035 164.993 55.1228C164.926 55.1422 164.867 55.1807 164.823 55.2333C164.779 55.2859 164.752 55.3504 164.743 55.4186C164.736 55.487 164.748 55.5561 164.78 55.6172C164.811 55.6785 164.86 55.7291 164.92 55.7628C164.979 55.7966 165.049 55.8119 165.116 55.807C165.21 55.7988 165.296 55.7537 165.357 55.6819C165.417 55.6099 165.447 55.5169 165.439 55.4234Z"
          fill="url(#paint4_linear)"
        />
        <path
          opacity="0.15"
          d="M164.918 55.7746C164.979 55.8173 165.055 55.8345 165.128 55.8221C165.176 55.8182 165.22 55.8048 165.263 55.7825C165.303 55.7605 165.341 55.7303 165.369 55.6936C165.4 55.6569 165.422 55.6147 165.434 55.5694C165.448 55.5241 165.451 55.4767 165.444 55.43C165.434 55.3732 165.414 55.3192 165.381 55.2715C165.349 55.2236 165.307 55.1834 165.257 55.1534C165.302 55.209 165.332 55.2749 165.344 55.3452C165.351 55.3957 165.347 55.4472 165.334 55.4964C165.319 55.5456 165.296 55.5916 165.264 55.6317C165.234 55.6719 165.193 55.7053 165.149 55.7301C165.105 55.7549 165.055 55.7707 165.005 55.7763C164.982 55.761 164.949 55.7678 164.918 55.7746Z"
          fill="#4A4F56"
        />
        <path
          d="M161.312 57.6585C161.302 57.5193 161.084 57.431 160.828 57.448C160.572 57.465 160.377 57.5957 160.385 57.7332C160.394 57.8706 160.612 57.9589 160.869 57.9436C161.125 57.9283 161.312 57.796 161.312 57.6585Z"
          fill="white"
        />
        <path
          d="M161.337 53.5411C161.364 53.6055 161.667 53.5886 162.016 53.6344C162.366 53.6802 162.653 53.7668 162.695 53.7125C162.717 53.687 162.67 53.6259 162.556 53.5597C162.398 53.4745 162.225 53.4209 162.047 53.4019C161.867 53.3779 161.685 53.3871 161.509 53.429C161.393 53.4698 161.325 53.5156 161.337 53.5411Z"
          fill="#4A4F56"
        />
        <path
          d="M159.377 53.5411C159.35 53.6055 159.046 53.5886 158.698 53.6344C158.35 53.6802 158.061 53.7668 158.019 53.7125C157.997 53.687 158.044 53.6259 158.156 53.5597C158.314 53.4747 158.487 53.4211 158.666 53.4019C158.845 53.3779 159.027 53.3871 159.204 53.429C159.321 53.4698 159.387 53.5156 159.377 53.5411Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.15"
          d="M127.594 87.1289C127.575 87.164 127.564 87.2029 127.561 87.2426C127.558 87.283 127.564 87.3236 127.578 87.3614C127.592 87.3984 127.614 87.4315 127.643 87.4582C127.671 87.4845 127.704 87.5036 127.741 87.5142C128.081 87.6041 129.153 86.3142 129.174 86.2362C129.194 86.1581 130.501 85.839 130.649 85.8051C130.796 85.7711 131.565 85.9527 131.796 86.2243C131.817 86.1931 131.831 86.1578 131.838 86.1208C131.845 86.0838 131.845 86.0457 131.838 86.0087C131.701 85.693 130.784 85.4775 130.626 85.5114L129.235 85.9069C129.189 85.9274 129.15 85.959 129.119 85.9985C128.919 86.2582 128.035 87.2986 127.729 87.2205C127.674 87.2085 127.625 87.1757 127.594 87.1289Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.15"
          d="M153.346 71.4314L150.025 79.7087C149.882 80.0607 149.645 80.3659 149.339 80.5899C149.032 80.8139 148.669 80.9478 148.29 80.9765L135.436 86.1649L135.419 86.1564C134.782 86.1243 134.143 86.1854 133.523 86.338C133.113 86.4738 132.675 86.8353 132.335 87.0729C131.865 87.3698 131.349 87.5877 130.808 87.7179C130.79 87.7214 130.771 87.7213 130.753 87.7173C130.735 87.7136 130.718 87.7063 130.702 87.6958C130.67 87.6746 130.648 87.6417 130.64 87.6041C130.623 87.6905 130.623 87.7793 130.64 87.8655C130.646 87.9088 130.669 87.948 130.702 87.9758C130.735 88.0025 130.776 88.0152 130.818 88.0115C131.364 87.8884 131.885 87.6702 132.356 87.3665C132.724 87.1539 133.126 87.0042 133.544 86.9236C133.627 86.9049 133.705 86.8896 133.771 86.8794L133.829 86.8692C134.518 86.765 135.214 86.7129 135.911 86.7131L149.477 82.6092C149.855 82.6094 150.223 82.4932 150.532 82.2764C150.841 82.0595 151.075 81.7527 151.204 81.3974L154.575 71.871L153.346 71.4314Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.15"
          d="M172.5 146.942C172.359 145.596 170.67 129.675 168.969 123.325L167.208 123.495L166.931 123.524C168.289 126.862 169.112 130.367 169.636 133.935L171.514 145.83C171.654 146.737 171.486 147.663 171.035 148.463L169.309 151.504C168.863 152.292 168.202 152.935 167.405 153.359C166.974 153.588 166.519 153.763 166.047 153.884L167.574 154.717L172.988 147.913L172.5 146.942Z"
          fill="#4A4F56"
        />
        <path
          d="M167.159 62.9811L171.901 63.8144C172.41 63.9054 172.878 64.1506 173.241 64.5167C173.606 64.883 173.849 65.3522 173.937 65.8612L174.652 73.0201L170.556 73.4631V85.6508H155.84L155.422 78.7397C155.422 78.7397 153.688 77.8368 153.756 74.4356L148.757 72.7689L151.256 65.5082C151.407 65.0364 151.677 64.6114 152.04 64.274C152.401 63.9366 152.844 63.6981 153.326 63.5819L157.23 62.9115L157.43 62.998C158.969 63.651 160.624 63.986 162.296 63.9831C163.966 63.9802 165.621 63.6392 167.157 62.9811H167.159Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M164.355 154.719L166.079 153.835C166.743 153.877 167.406 153.743 168.002 153.449C168.598 153.154 169.105 152.708 169.474 152.154L172.574 147.088C173.447 148.548 174.261 150.079 174.192 151.794L174.297 155.841L173.834 155.888L173.017 151.8C173.011 151.733 172.982 151.671 172.938 151.621C172.892 151.57 172.834 151.536 172.768 151.521C172.703 151.506 172.634 151.513 172.573 151.538C172.512 151.564 172.459 151.609 172.423 151.665L170.297 154.877C169.696 155.791 168.708 156.251 167.622 156.36L163.84 156.324C163.773 156.331 163.703 156.321 163.638 156.295C163.576 156.27 163.52 156.228 163.476 156.175C163.431 156.122 163.403 156.058 163.389 155.992C163.375 155.924 163.379 155.854 163.399 155.788C163.555 155.272 163.898 154.975 164.355 154.719Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M150.585 154.695L152.43 153.573C153.085 153.682 153.757 153.614 154.38 153.38C155.001 153.145 155.551 152.753 155.972 152.239L159.966 147.395C160.69 148.94 160.947 150.664 160.704 152.353L160.129 156.362H159.664L159.535 152.239C159.533 152.173 159.511 152.108 159.472 152.054C159.433 152 159.377 151.961 159.314 151.939C159.251 151.918 159.182 151.917 159.119 151.937C159.054 151.956 158.998 151.995 158.958 152.047L156.52 155.034C156.179 155.454 155.748 155.793 155.261 156.026C154.772 156.26 154.239 156.38 153.698 156.382H149.896C149.827 156.382 149.759 156.367 149.699 156.335C149.638 156.302 149.586 156.256 149.548 156.199C149.509 156.141 149.487 156.075 149.48 156.007C149.474 155.939 149.485 155.87 149.513 155.807C149.713 155.311 150.098 154.914 150.585 154.695Z"
          fill="url(#paint7_linear)"
        />
        <path
          opacity="0.6"
          d="M166.582 85.7642L166.49 85.5369C165.063 86.1089 164.226 87.6058 164.197 87.655L164.246 87.6294C164.076 87.986 163.958 88.3648 163.895 88.7546C163.768 89.3741 163.647 89.9615 162.927 90.6812C162.208 91.4008 161.619 91.5296 161.001 91.6501C160.383 91.7706 159.735 91.9099 158.964 92.6771C158.194 93.4442 158.067 94.0858 157.946 94.7137C157.826 95.3417 157.7 95.9188 156.977 96.6401C156.254 97.3614 155.67 97.4887 155.051 97.6092C154.526 97.6856 154.024 97.875 153.579 98.164L153.554 98.4389C154.105 98.0146 154.587 97.9162 155.093 97.8127C155.714 97.6854 156.357 97.553 157.13 96.7943C157.902 96.0357 158.028 95.384 158.148 94.7577C158.269 94.1314 158.396 93.5528 159.117 92.8315C159.838 92.1102 160.424 91.9827 161.044 91.8622C161.663 91.7417 162.31 91.6026 163.08 90.8439C163.851 90.0853 163.978 89.4352 164.099 88.8073C164.199 88.3083 164.297 87.8332 164.704 87.2952H164.676C165.154 86.6163 165.814 86.0859 166.582 85.7642Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.2"
          d="M170.56 73.4631L170.074 73.5327L169.517 68.9519L169.076 85.6508L170.56 85.5897V73.4631Z"
          fill="#4A4F56"
        />
        <path
          d="M175.21 80.5079L174.586 73.0401L170.281 73.456L170.629 76.4532L165.696 72.161C165.443 71.516 165.801 71.0612 165.631 69.9275C165.422 68.9736 164.798 67.4291 164.798 67.4291C163.825 66.735 164.798 69.3725 164.538 69.4336C163.52 69.6729 162.298 65.4858 161.604 65.9017C161.155 66.1698 162.079 66.9013 162.573 68.6376L162.422 68.7445C162.45 68.8718 161.454 67.5564 160.857 66.5975C160.443 65.9407 160.268 66.4278 160.285 66.6993C160.302 66.9709 160.948 67.9841 161.242 68.4628C161.439 68.7767 161.646 69.1026 161.77 69.2961L161.637 69.5015C160.633 68.5391 160.071 67.2425 159.66 67.4291C159.136 68.0656 160.848 69.5744 161.266 70.2007C160.142 69.7662 160.078 68.6732 159.521 68.9566C159.182 69.1264 159.922 70.0089 160.354 70.4841C161.542 71.2241 162.274 72.643 163.155 73.1335L164.382 74.6491L170.354 82.4342C170.937 83.035 171.688 83.4437 172.508 83.6053C173.506 83.7479 174.343 83.2234 174.774 82.312L174.966 81.9064C175.173 81.4711 175.258 80.9877 175.21 80.5079Z"
          fill="#FC9D9D"
        />
        <path
          opacity="0.15"
          d="M170.614 76.4195L172.909 78.558L170.57 75.3095L170.614 76.4195Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.2"
          d="M153.829 74.5052L154.863 70.683C155.219 68.682 154.318 70.3589 154.248 70.4879L152.303 73.9587L153.829 74.5052Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.1"
          d="M155.529 78.67C156.918 78.9989 158.365 78.9989 159.754 78.67C159.754 78.67 160.755 78.5308 161.424 78.67C161.424 78.67 164.594 79.225 166.262 78.5308C166.262 78.5308 165.074 79.6425 161.395 79.0858C160.85 79.0056 160.298 79.0056 159.754 79.0858C159.754 79.0858 156.96 79.5034 155.582 79.0858L155.529 78.67Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.3"
          d="M169.104 85.5846C169.178 85.7679 172.544 93.7805 170.351 104.88C170.351 104.88 169.102 112.794 169.38 116.959V123.602L171.768 123.672L171.822 123.322C171.653 120.683 171.682 113.434 172.639 106.659C174.455 93.8127 170.561 85.5846 170.561 85.5846H169.104Z"
          fill="#4A4F56"
        />
        <path
          opacity="0.6"
          d="M171.047 89.8852C171.047 89.7375 171.047 89.5457 171.047 89.3658C171.047 88.5461 171.047 88.5461 170.976 88.5172L170.913 88.4901L170.864 88.5376C169.704 89.656 169.496 90.6133 169.294 91.5383C169.092 92.4632 168.898 93.3577 167.792 94.4337C166.685 95.5097 165.799 95.6828 164.86 95.8628C163.922 96.0427 162.973 96.2243 161.821 97.3461C160.668 98.468 160.463 99.4218 160.259 100.343C160.056 101.265 159.862 102.159 158.756 103.229C157.649 104.298 156.761 104.478 155.823 104.658C155.047 104.807 154.248 104.962 153.333 105.666C153.333 105.751 153.333 105.836 153.333 105.924C154.255 105.169 155.03 105.019 155.855 104.861C156.783 104.692 157.741 104.5 158.893 103.378C160.045 102.256 160.251 101.302 160.456 100.381C160.662 99.4591 160.853 98.5647 161.96 97.4955C163.067 96.4262 163.954 96.2463 164.893 96.0664C165.831 95.8865 166.778 95.7049 167.931 94.5831C169.083 93.4612 169.289 92.5074 169.49 91.5841C169.682 90.6964 169.866 89.8563 170.83 88.8584C170.83 89.0043 170.83 89.1978 170.83 89.3675C170.83 90.1635 170.83 90.1635 170.869 90.1991C170.875 90.2068 170.882 90.2137 170.891 90.2195L170.911 90.2416C170.948 90.2772 170.967 90.2959 171.064 90.2212C171.071 90.2109 171.079 90.2012 171.089 90.1924C171.166 90.1262 171.284 90.0108 171.473 89.8241L171.383 89.5338L171.047 89.8852Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.6"
          d="M172.841 94.8597C172.736 94.9632 172.609 95.0922 172.446 95.2569C172.252 95.452 172.028 95.6778 171.879 95.8254C171.879 95.6761 171.879 95.486 171.879 95.3044C171.879 94.4846 171.879 94.4846 171.807 94.4558L171.745 94.4286L171.694 94.4761C170.535 95.5963 170.327 96.5518 170.126 97.4785C169.924 98.4052 169.728 99.2962 168.623 100.364C167.519 101.431 166.629 101.613 165.691 101.793C164.752 101.973 163.805 102.154 162.653 103.276C161.5 104.398 161.295 105.352 161.091 106.275C160.888 107.198 160.694 108.09 159.587 109.16C158.481 110.231 157.593 110.408 156.655 110.588C155.776 110.758 154.866 110.927 153.792 111.908C153.792 111.996 153.803 112.078 153.81 112.175C154.895 111.139 155.769 110.97 156.695 110.793C157.622 110.617 158.581 110.432 159.733 109.308C160.886 108.185 161.091 107.232 161.297 106.311C161.502 105.389 161.692 104.496 162.8 103.426C163.909 102.355 164.795 102.176 165.731 101.997C166.668 101.817 167.619 101.635 168.771 100.513C169.924 99.3913 170.129 98.4374 170.329 97.5142C170.523 96.6282 170.706 95.7881 171.67 94.7884C171.67 94.9344 171.67 95.1279 171.67 95.2976C171.67 96.0936 171.67 96.0936 171.711 96.1292C171.717 96.1374 171.724 96.1442 171.733 96.1496L171.753 96.1717C171.79 96.2073 171.809 96.2277 171.906 96.1513L171.931 96.1207C172.045 96.024 172.247 95.8203 172.636 95.43L172.875 95.189C172.867 95.0871 172.853 94.9734 172.841 94.8597Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.6"
          d="M172.863 104.002C172.668 104.199 172.444 104.425 172.296 104.571C172.296 104.423 172.296 104.232 172.296 104.052C172.296 103.232 172.296 103.232 172.225 103.203L172.162 103.176L172.113 103.223C170.954 104.342 170.745 105.299 170.543 106.224C170.341 107.149 170.147 108.044 169.042 109.12C167.938 110.196 167.048 110.369 166.11 110.549C165.171 110.729 164.224 110.91 163.072 112.032C161.919 113.154 161.714 114.108 161.509 115.029C161.303 115.951 161.111 116.845 160.005 117.923C158.898 119.001 158.011 119.172 157.072 119.352C156.273 119.505 155.449 119.664 154.499 120.425C154.508 120.508 154.516 120.594 154.523 120.674C155.475 119.871 156.273 119.717 157.111 119.556C158.039 119.386 158.998 119.194 160.149 118.072C161.3 116.951 161.507 115.997 161.712 115.075C161.918 114.154 162.109 113.259 163.216 112.19C164.322 111.121 165.21 110.941 166.149 110.761C167.087 110.581 168.034 110.399 169.187 109.277C170.339 108.156 170.544 107.202 170.746 106.278C170.94 105.391 171.122 104.551 172.086 103.553C172.086 103.699 172.086 103.892 172.086 104.062C172.086 104.858 172.086 104.858 172.125 104.893L172.147 104.914L172.167 104.936C172.204 104.972 172.225 104.99 172.32 104.916L172.345 104.887C172.445 104.802 172.61 104.637 172.907 104.34L172.943 103.921L172.863 104.002Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.6"
          d="M170.271 116.518C169.345 116.696 168.386 116.879 167.233 118.001C166.081 119.123 165.876 120.079 165.67 121C165.501 121.811 165.331 122.584 164.555 123.482H164.818C165.533 122.592 165.706 121.805 165.874 121.038C166.078 120.104 166.271 119.223 167.378 118.144C168.484 117.064 169.372 116.896 170.31 116.716C170.828 116.635 171.334 116.494 171.819 116.296C171.819 116.219 171.819 116.143 171.819 116.066C171.325 116.283 170.804 116.435 170.271 116.518Z"
          fill="#F4F4F4"
        />
        <path
          opacity="0.15"
          d="M172.513 83.6002C173.511 83.7428 174.348 83.2183 174.779 82.3069L174.971 81.9013C175.025 81.7862 175.071 81.6671 175.108 81.5449C173.626 83.7513 171.5 81.5449 171.5 81.5449L163.523 72.7194C163.29 72.6413 162.807 72.2102 162.352 71.7587C161.575 70.9918 160.714 70.3142 159.786 69.7391L159.721 69.6983C159.918 69.9714 160.13 70.2321 160.359 70.479C161.547 71.219 162.279 72.6379 163.16 73.1284L164.387 74.644L170.359 82.4291C170.941 83.0299 171.692 83.4385 172.513 83.6002Z"
          fill="#4A4F56"
        />
        <path
          d="M22.8485 119.471L21.6299 149.559"
          stroke="#4A4F56"
          stroke-width="0.157841"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M14.001 132.55L19.4083 138.895"
          stroke="#4A4F56"
          stroke-width="0.157841"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M26.5868 140.092L21.7158 147.487"
          stroke="#4A4F56"
          stroke-width="0.157841"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M28.9598 127.677L22.249 135.348"
          stroke="#4A4F56"
          stroke-width="0.157841"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M28.5066 131.796C30.0969 129.929 30.5789 127.723 29.581 126.874C28.583 126.026 26.4853 126.861 24.8933 128.728C23.3013 130.595 22.8227 132.801 23.8206 133.65C24.8186 134.498 26.9164 133.672 28.5066 131.801"
          fill="url(#paint8_linear)"
        />
        <g opacity="0.3">
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M29.6435 126.95L23.7881 133.617C23.8101 133.617 23.8101 133.639 23.8305 133.639C24.8353 134.488 26.9296 133.661 28.5097 131.801C30.0712 129.985 30.5617 127.848 29.6435 126.95Z"
              fill="#4A4F56"
            />
          </g>
        </g>
        <path
          d="M17.2354 136.292C18.0076 135.652 17.6597 133.955 16.4564 132.501C15.253 131.046 13.6509 130.384 12.8769 131.022C12.103 131.66 12.4526 133.361 13.6559 134.816C14.8593 136.27 16.4614 136.932 17.2354 136.292Z"
          fill="url(#paint9_linear)"
        />
        <g opacity="0.3">
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M12.8258 131.075L17.2487 136.268L17.2284 136.289C16.4578 136.93 14.8523 136.268 13.6371 134.814C12.4847 133.403 12.1198 131.737 12.8258 131.08"
              fill="#4A4F56"
            />
          </g>
        </g>
        <path
          d="M14.814 141.161L20.52 145.606"
          stroke="#4A4F56"
          stroke-width="0.157841"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.7338 144.172C19.3601 143.388 18.6727 141.796 17.2063 140.618C15.7399 139.44 14.0275 139.116 13.4012 139.9C12.7749 140.684 13.4623 142.276 14.9287 143.454C16.3951 144.632 18.1059 144.955 18.7321 144.172"
          fill="url(#paint10_linear)"
        />
        <g opacity="0.3">
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M13.3608 139.965L18.746 144.152L18.724 144.174C18.1045 144.965 16.3954 144.644 14.9205 143.468C13.4881 142.315 12.8041 140.752 13.3608 139.965Z"
              fill="#4A4F56"
            />
          </g>
        </g>
        <path
          d="M25.078 121.733C25.1883 119.281 24.2379 117.246 22.9514 117.188C21.6649 117.13 20.5346 119.07 20.4243 121.523C20.3139 123.975 21.2627 126.01 22.5492 126.068C23.8357 126.126 24.966 124.186 25.078 121.733Z"
          fill="url(#paint11_linear)"
        />
        <g opacity="0.3">
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M20.4326 121.545C20.5395 119.108 21.6716 117.163 22.9547 117.227L22.549 126.095C21.2659 126.01 20.3257 123.981 20.4326 121.545Z"
              fill="#4A4F56"
            />
          </g>
        </g>
        <path
          d="M27.107 142.884C28.1745 141.214 28.295 139.381 27.3667 138.789C26.4383 138.196 24.8208 139.072 23.7584 140.742C22.6959 142.412 22.5703 144.247 23.4987 144.837C24.4271 145.428 26.0445 144.554 27.107 142.884Z"
          fill="url(#paint12_linear)"
        />
        <g opacity="0.3">
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M23.7664 140.733C22.7192 142.358 22.5919 144.153 23.4456 144.773L27.3577 138.788L27.1218 138.681C26.2036 138.342 24.7457 139.191 23.7664 140.733Z"
              fill="#4A4F56"
            />
          </g>
        </g>
        <path
          d="M21.0967 149.196L17.7617 128.425"
          stroke="#4A4F56"
          stroke-width="0.157841"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.0769 131.333C19.2191 131.15 19.8623 129.235 19.5144 127.056C19.1665 124.877 17.9564 123.259 16.8141 123.441C15.6719 123.622 15.0304 125.539 15.3783 127.718C15.7262 129.897 16.9363 131.516 18.0769 131.333Z"
          fill="url(#paint13_linear)"
        />
        <g opacity="0.3">
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M15.3688 127.719C15.0293 125.54 15.6692 123.617 16.8012 123.426L18.0674 131.331C16.9336 131.545 15.7167 129.921 15.3739 127.719"
              fill="#4A4F56"
            />
          </g>
        </g>
        <path
          d="M19.6219 156.547H23.6171C23.794 156.547 23.9693 156.513 24.1329 156.445C24.2965 156.379 24.4452 156.279 24.5703 156.155C24.6953 156.029 24.7945 155.88 24.8618 155.717C24.9292 155.554 24.9637 155.377 24.963 155.201V149.9H18.2539V155.204C18.2609 155.562 18.408 155.903 18.6636 156.156C18.9192 156.407 19.2632 156.548 19.6219 156.55"
          fill="#868A91"
        />
        <path
          d="M25.1344 149.196H18.1249C18.0287 149.196 17.9362 149.235 17.8681 149.303C17.8001 149.371 17.7617 149.462 17.7617 149.559C17.7617 149.656 17.8001 149.748 17.8681 149.816C17.9362 149.883 18.0287 149.922 18.1249 149.922H25.1344C25.1823 149.922 25.2296 149.914 25.2739 149.895C25.3182 149.878 25.3584 149.851 25.3922 149.817C25.4262 149.783 25.4528 149.743 25.471 149.698C25.489 149.654 25.4981 149.607 25.4976 149.559C25.4913 149.464 25.4509 149.376 25.3842 149.31C25.3174 149.242 25.2286 149.203 25.1344 149.196Z"
          fill="#868A91"
        />
        <path
          d="M24.9848 149.9H18.8749C18.7785 149.9 18.6861 149.861 18.6181 149.793C18.5498 149.726 18.5117 149.634 18.5117 149.537C18.5117 149.44 18.5498 149.349 18.6181 149.281C18.6861 149.211 18.7785 149.174 18.8749 149.174H18.1264C18.03 149.174 17.9377 149.211 17.8696 149.281C17.8014 149.349 17.7632 149.44 17.7632 149.537C17.7632 149.634 17.8014 149.726 17.8696 149.793C17.9377 149.861 18.03 149.9 18.1264 149.9H18.2774V155.179C18.2766 155.355 18.3107 155.532 18.3781 155.695C18.4453 155.858 18.5444 156.007 18.6697 156.133C18.7948 156.258 18.9434 156.357 19.1072 156.424C19.271 156.491 19.4463 156.525 19.6233 156.525H21.8009C21.3406 156.526 20.894 156.367 20.5388 156.075C20.1834 155.783 19.9419 155.374 19.8558 154.922L18.8952 149.987H24.9848V149.9Z"
          fill="#61656D"
        />
        <path
          d="M151.977 44.5458C151.977 41.1717 148.671 38.4358 144.594 38.4358C140.518 38.4358 137.211 41.1717 137.211 44.5458C137.211 47.9199 140.518 50.6558 144.594 50.6558C145.38 50.6568 146.163 50.5517 146.921 50.3435L150.11 51.6741L149.043 49.4219C150.825 48.3051 151.977 46.5366 151.977 44.5458Z"
          fill="white"
          stroke="#B2B6BB"
          stroke-width="0.339443"
          stroke-miterlimit="10"
        />
        <path
          d="M146.619 42.5297C146.407 42.168 146.063 41.9019 145.66 41.7863C144.723 41.53 143.683 41.6539 143.176 42.2089C142.57 42.6536 142.468 43.7686 143.306 43.7686C143.826 43.7686 143.956 42.7282 144.964 42.9726C145.054 43.0015 145.136 43.0536 145.2 43.1239C145.265 43.1941 145.311 43.2802 145.331 43.3732C145.501 43.9842 144.71 44.4102 144.153 45.0704C144.065 45.1739 144.009 45.3007 143.99 45.4356C143.972 45.5704 143.992 45.7077 144.05 45.8311C144.106 45.9546 144.197 46.0592 144.313 46.1325C144.426 46.2058 144.56 46.2449 144.696 46.2449C144.839 46.2444 144.978 46.2046 145.1 46.1296C145.221 46.0548 145.319 45.9479 145.384 45.8206C145.878 44.8311 147.524 44.0368 146.619 42.5297Z"
          fill="#B2B6BB"
        />
        <path
          d="M144.601 46.9017C144.436 46.8688 144.266 46.8895 144.115 46.9605C143.966 47.0316 143.84 47.1492 143.762 47.2962C143.683 47.4431 143.654 47.6117 143.678 47.7766C143.703 47.9416 143.779 48.0945 143.896 48.2127C144.015 48.3308 144.168 48.4078 144.333 48.4326C144.497 48.4572 144.665 48.4282 144.813 48.3498C144.961 48.2712 145.078 48.1475 145.149 47.9968C145.22 47.8461 145.242 47.6763 145.21 47.5127C145.18 47.3622 145.107 47.2239 144.998 47.1151C144.889 47.0065 144.75 46.9321 144.601 46.9017Z"
          fill="#B2B6BB"
        />
        <path
          d="M170.996 48.4256C170.966 48.4258 170.935 48.4176 170.91 48.402C170.882 48.3862 170.86 48.3638 170.847 48.3368C170.832 48.3099 170.825 48.2793 170.826 48.2486C170.826 48.218 170.837 48.1882 170.854 48.1625L174.068 43.2644C174.094 43.2265 174.131 43.2002 174.175 43.1909C174.221 43.1817 174.267 43.1904 174.304 43.2152C174.341 43.2399 174.368 43.2786 174.377 43.3229C174.387 43.3672 174.379 43.4132 174.353 43.4511L171.137 48.3492C171.122 48.3726 171.101 48.3918 171.076 48.4051C171.052 48.4185 171.023 48.4254 170.996 48.4256Z"
          fill="#868A91"
        />
        <path
          d="M178.963 54.2199H178.939L172.707 53.3442C172.685 53.3409 172.663 53.3335 172.644 53.3221C172.624 53.3107 172.607 53.2956 172.593 53.2776C172.58 53.2596 172.571 53.2393 172.564 53.2174C172.559 53.1957 172.557 53.1729 172.561 53.1507C172.564 53.1285 172.571 53.1069 172.583 53.0875C172.595 53.0682 172.61 53.0512 172.627 53.0376C172.646 53.0241 172.666 53.0142 172.688 53.0085C172.709 53.0029 172.732 53.0015 172.754 53.0047L178.986 53.8805C179.031 53.8835 179.073 53.9046 179.104 53.9385C179.132 53.9726 179.148 54.0171 179.144 54.0621C179.141 54.1071 179.121 54.149 179.087 54.1785C179.053 54.2082 179.007 54.223 178.963 54.2199Z"
          fill="#868A91"
        />
        <path
          d="M173.192 50.2366C173.155 50.2384 173.117 50.2279 173.087 50.2067C173.056 50.1855 173.034 50.1549 173.022 50.1194C173.009 50.0768 173.012 50.0305 173.031 49.9905C173.051 49.9504 173.087 49.9198 173.129 49.9056L180.597 47.4395C180.619 47.4316 180.641 47.4282 180.663 47.4292C180.687 47.4304 180.709 47.4362 180.729 47.446C180.75 47.456 180.768 47.4699 180.784 47.4872C180.799 47.5044 180.809 47.5244 180.818 47.5461C180.825 47.5679 180.826 47.5908 180.825 47.6135C180.823 47.6363 180.816 47.6583 180.806 47.6785C180.794 47.6985 180.78 47.7164 180.762 47.7308C180.745 47.745 180.724 47.7557 180.702 47.762L173.235 50.2281C173.221 50.2325 173.206 50.2354 173.192 50.2366Z"
          fill="#868A91"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M116.844 74.5865C116.805 74.5875 116.767 74.5744 116.736 74.55C116.705 74.5255 116.683 74.4911 116.675 74.4524L108.189 35.2467C108.179 35.2018 108.188 35.1549 108.213 35.1164C108.238 35.0779 108.278 35.0509 108.323 35.0414C108.368 35.032 108.415 35.0409 108.453 35.066C108.492 35.0911 108.519 35.1305 108.528 35.1754L117.014 74.3811C117.019 74.403 117.019 74.4256 117.015 74.4476C117.011 74.4695 117.003 74.4906 116.991 74.5092C116.979 74.5281 116.963 74.5442 116.945 74.5569C116.926 74.5697 116.905 74.5785 116.884 74.5831L116.844 74.5865Z"
            fill="white"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M93.0838 74.5865H93.0481C93.0262 74.5819 93.0055 74.5729 92.987 74.5602C92.9687 74.5476 92.9529 74.5313 92.9409 74.5126C92.9286 74.4938 92.9203 74.4729 92.9163 74.4509C92.9124 74.429 92.9127 74.4064 92.9174 74.3845L101.404 35.1789C101.408 35.1566 101.417 35.1354 101.43 35.1166C101.443 35.0977 101.459 35.0818 101.478 35.0692C101.497 35.0568 101.519 35.0482 101.541 35.0441C101.564 35.0399 101.586 35.04 101.609 35.0448C101.631 35.0494 101.652 35.0585 101.671 35.0713C101.69 35.0842 101.706 35.1006 101.718 35.1196C101.731 35.1388 101.739 35.16 101.744 35.1824C101.748 35.2048 101.748 35.2279 101.743 35.2501L93.2569 74.4558C93.2477 74.4943 93.2255 74.5284 93.1939 74.5524C93.1623 74.5763 93.1233 74.5883 93.0838 74.5865Z"
            fill="white"
          />
        </g>
        <path
          d="M164.401 19.3084C164.468 19.3084 164.533 19.2816 164.58 19.2337C164.628 19.1861 164.655 19.1214 164.655 19.0538C164.655 18.9863 164.628 18.9215 164.58 18.8738C164.533 18.8261 164.468 18.7993 164.401 18.7993C164.333 18.7993 164.268 18.8261 164.221 18.8738C164.173 18.9215 164.146 18.9863 164.146 19.0538C164.146 19.1214 164.173 19.1861 164.221 19.2337C164.268 19.2816 164.333 19.3084 164.401 19.3084Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="73.4521"
          y1="32.2184"
          x2="128.554"
          y2="94.3422"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="45.0596"
          y1="84.87"
          x2="110.145"
          y2="183.311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="36.7373"
          y1="87.0387"
          x2="100.104"
          y2="181.886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="57.6304"
          y1="77.4176"
          x2="63.4828"
          y2="92.1698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="164.741"
          y1="55.1009"
          x2="165.44"
          y2="55.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="148.757"
          y1="62.6588"
          x2="171.306"
          y2="88.3362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="163.381"
          y1="146.985"
          x2="172.531"
          y2="157.757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="149.479"
          y1="147.295"
          x2="158.232"
          y2="158.316"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="23.2998"
          y1="126.46"
          x2="30.7107"
          y2="133.233"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="12.4653"
          y1="130.715"
          x2="18.1904"
          y2="135.868"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="13.1694"
          y1="139.415"
          x2="18.2585"
          y2="145.168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="20.4155"
          y1="117.088"
          x2="27.7339"
          y2="120.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="22.875"
          y1="138.532"
          x2="29.1322"
          y2="143.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="15.2837"
          y1="123.339"
          x2="21.9469"
          y2="126.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A92FF" />
          <stop offset="1" stop-color="#2E66FE" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="206" height="156.98" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
